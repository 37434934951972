import './App.css'
import ContactUs from './Components/ContactUs'
import Crew from './Components/Crew'
import Hero from './Components/Hero'
import LineDrawing from './Components/LineDrawing'
import Navbar from './Components/Navbar'
import Services from './Components/Services'
import InstaFeed from './Components/InstaFeed'

function App() {
  return (
    <div className='App'>
      <Navbar />
      <Hero />
      <Crew />
      <Services />
      <ContactUs />
      {/* <InstaFeed/> */}
      <LineDrawing />
    </div>
  )
}

export default App
