import React from 'react'
import './style.css'
import logo from '../../media/carvedLogo.webp'

export default function Navbar() {
  return (
    <div id='navbarWrapper'>
      <img alt='man getting a shave' src={logo} />
      <ul>
        <li><a href='#heroWrapper'>Home</a></li>
        <li><a href='#crewWrapper'>Crew</a></li>
        <li><a href='#servicesWrapper'>Services</a></li>
        <li><a href='#contactUsWrapper'>Contact Us</a></li>
      </ul>
    </div>
  )
}
