import React from 'react'
import './style.css'
import icon from "./icon.png"
import Haircut from "./Haircut.png"
import shave from "./shave.png"
import beard from "./beard.png"

import serviceImage from '../../media/serviceImage.png'

const menuArray = [
  {
    service: 'Haircut',
    description: 'One guard all over the head with straight razor on the back of the neck.',
    icon:Haircut,
    backgroundUrl: '',
  },
  {
    service: 'Scissor Cut',
    description: 'For longer hairstyles, including consultation, haircut, and style.',
    icon:icon,
    backgroundUrl: '',
  },
  {
    service: 'Skin Fade',
    description: 'Modern style haircut. Zero guard with blade finish.',
    icon:shave,
    backgroundUrl: '',
  },
  {
    service: 'Classic Cut',
    description: 'Classic style haircut with half guard and up.',
    icon:icon,
    backgroundUrl: '',
  },
  {
    service: 'Beard Trim',
    description: 'Beard service with trimming and blade edging.',
    icon:shave,
    backgroundUrl: '',
  },
  {
    service: 'Classic Cut & Beard Trim',
    description: 'Combo of both Classic Cut & Beard Trim',
    icon:beard,
    backgroundUrl: '',
  },
  {
    service: 'Skin Fade & Beard Trim',
    description: 'Combo Skin Fade & Beard Trim',
    icon:shave,
    backgroundUrl: '',
  },
  {
    service: 'Carved Shave',
    description: 'Traditional straight razor shave with hot & cold towel facial.',
    icon:icon,
    backgroundUrl: '',
  },
]

export default function Services() {
  const linkUrl = "https://carvedbarbershop.square.site/s/appointments"

  return (
    <div id='servicesWrapper'>
      <div className='grid-container'>
        {menuArray &&
          menuArray.map(menuBullet => (
            <div className='service-card-container' style={{ '--backgroundImage': `url(${menuBullet.backgroundUrl})` }}>
              <img src={menuBullet.icon}/>
              <div className="service-description-container">
              <p className='service-service'>{menuBullet.service}</p>
              <p className='service-description'>{menuBullet.description}</p>
              </div>
            </div>
          ))}
      </div>
      <img id="serviceImage" src={serviceImage} alt="barber cutting hair"/>
      <a target='_blank' href="https://carvedbarbershop.square.site/s/appointments">
        BOOK NOW
      </a>
    </div>
  )
}
