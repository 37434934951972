import React from 'react'
import CrewCard from '../CrewCard'
import './style.css'
import abood from '../../media/abood.png'
import gary from '../../media/gary.png'
import brees from '../../media/brees.png'
import gustavo from '../../media/gustavo.png'
import ben from '../../media/ben.png'
import oscar from '../../media/oscar.png'
import abe from '../../media/abe.png'
import luis from '../../media/luis.png'
import duang from '../../media/duang.png'
import andres from '../../media/andres.png'

export default function Crew() {
  const crewInfoArray = [
    {
      name: 'Abood',
      imgUrl: abood,
    },
    {
      name: 'Gary',
      imgUrl: gary,
    },
    {
      name: 'Brees',
      imgUrl: brees,
    },
    {
      name: 'Luis',
      imgUrl: luis,
    },
    {
      name: 'Gustavo',
      imgUrl: gustavo,
    },
    {
      name: 'Duang',
      imgUrl: duang,
    },

    {
      name: 'Oscar',
      imgUrl: oscar,
    },
    {
      name: 'Ben',
      imgUrl: ben,
    },
    {
      name: 'Abe',
      imgUrl: abe,
    },
    {
      name: 'Andres',
      imgUrl: andres,
    },
  ]
  return (
    <div id='crewWrapper'>
      <h2>Meet the Crew</h2>
      <div className='crewContentContainer'>{crewInfoArray && crewInfoArray.map(crewMember => <CrewCard name={crewMember.name} imgUrl={crewMember.imgUrl} />)}</div>
    </div>
  )
}
