import React from 'react'
import './style.css'
import map from './map.png'
import imageUrl from '../../media/building.webp'
export default function ContactUs() {
  return (
    <div id='contactUsWrapper'>
      <div id="header"><h2>Location & Hours</h2></div>
      

      <img id='buildingImage' src={imageUrl} />

      <div id='location' className='contactMeContainer'>
        <p>1201 Northeast 26th Street</p>
        <p>Suite 103</p>
        <p>Wilton Manors, FL 33305</p>
        <p>(954)-364-3652</p>
        <p>carvedbarbershop@gmail.com</p>
        <br />
        <p>Free parking in the rear off NE 14th Ave</p>
        <a target='_blank' href='https://www.google.com/maps/dir/26.1390814,-80.131692/1201+NE+26th+St,+Wilton+Manors,+FL+33305/@26.1493808,-80.138683,15z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x88d9019060a85083:0x1643221127dacaa5!2m2!1d-80.1303405!2d26.1597722'>
          Get Direction
        </a>
      </div>

      <img id='map' src={map} />

      <div id='hours' className='contactMeContainer'>
        <p>Monday 9:00am - 7:00pm</p>
        <p>Tuesday 9:00am - 7:00pm</p>
        <p>Wednesday 9:00am - 7:00pm</p>
        <p>Thursday 9:00am - 7:00pm</p>
        <p>Friday 9:00am - 6:00pm</p>
        <p>Saturday 9:00am - 5:00pm</p>
        <p>Sunday 11:00am - 5:00pm</p>
      </div>
    


    </div>
  )
}
