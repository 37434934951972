import React from 'react'
import './style.css'
import logo from '../../media/carvedLogo.webp'
function Hero() {
  return (
    <div id='heroWrapper'>
      <img alt='man getting a shave' src={logo} className='heroLogo' />
      <a target='_blank' href="https://carvedbarbershop.square.site/s/appointments">
        BOOK NOW
      </a>
      <h5>scroll</h5>
      <div className='heroShadow' />
    </div>
  )
}

export default Hero
