import React from 'react'
import './style.css'

function CrewCard({ color, name, imgUrl }) {
  const linkUrl = 'https://carvedbarbershop.square.site/s/appointments'
  let styleVariable
  color === 'red' ? (styleVariable = { '--color': `red`, '--direction': '-45deg' }) : (styleVariable = { '--color': '#002366', '--direction': `45deg` })

  return (
    <div className='crewCard'>
      <div className='box' style={styleVariable}>
        <p>{name}</p>
        <img alt='profile of barber' src={imgUrl} className='crewCardPicture' />
      </div>
      <a target='_blank' href={linkUrl}>
        Book Now
      </a>
    </div>
  )
}

export default CrewCard
